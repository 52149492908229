import {
  DownloadOrderVideos,
  DownloadOrderVideosVariables,
  DownloadOrderVideos_downloadOrderVideos_DownloadVideosResults,
} from "@/api/jobs/__generated__/DownloadOrderVideos";
import {
  GetOrderDownloadProgress,
  GetOrderDownloadProgressVariables,
  GetOrderDownloadProgress_getOrderDownloadProgress_Download,
} from "@/api/jobs/__generated__/GetOrderDownloadProgress";
import {
  downloadOrderVideosGql,
  getOrderDownloadProgressGql,
} from "@/api/jobs/downloadOrderVideos";
import { apiErrorCodes } from "@/shared/utils/constants";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useQuery } from "@vue/apollo-composable";
import { Ref, computed, ref, unref, watch } from "vue";

const DOWNLOAD_PROGRESS_POLL_INTERVAL = 5000;

export const useDownloadOrderVideos = (orderIdRef: Ref<string | null>) => {
  const {
    result: downloadOrderVideosResult,
    loading: downloadOrderVideosLoading,
    refetch: refetchDownloadOrderVideos,
  } = useQuery<DownloadOrderVideos, DownloadOrderVideosVariables>(
    downloadOrderVideosGql,
    () => ({
      input: { orderId: unref(orderIdRef) ?? "" },
    }),
    () => ({ enabled: !!unref(orderIdRef), fetchPolicy: "cache-first" })
  );

  /** List of Videos. */
  const downloadableOrderVideos = computed(() => {
    const parsedResponse =
      parseGqlResponse<DownloadOrderVideos_downloadOrderVideos_DownloadVideosResults>(
        "DownloadVideosResults",
        downloadOrderVideosResult.value,
        // Hide error if not allowed to download videos. Just return empty array.
        apiErrorCodes.DOWNLOAD_VIDEOS_NOT_ALLOWED_ERROR
      );

    return parsedResponse.data?.results?.filter((id) => !!id?.embedUrl) ?? [];
  });

  /**
   * FIXME: Remove once single link is proven to work stable. Keep logic as backup.
   */
  // const downloadAllVideos = async () => {
  //   try {
  //     const links = filteredDownloadableOrderVideos.value.map(
  //       (item, index) => ({
  //         link: item?.downloadUrl ?? "",
  //         filename: generateJobDownloadFilename({
  //           orderId: item?.job?.order?.id,
  //           jobId: item?.job?.id,
  //           hasBackgroundSound: item?.hasBackgroundSound,
  //           index: index,
  //           fileExtension: "mp4",
  //         }),
  //       })
  //     );

  //     await downloadFiles({
  //       files: links,
  //       showIndividualProgressBars: false,
  //       zipFileName: generateJobDownloadFilename({
  //         orderId: orderIdRef.value,
  //         fileExtension: "zip",
  //       }),
  //     });

  //     if (hasError.value) {
  //       message.warning(
  //         i18nTranslate(
  //           "Failed to download some of the videos. Please check your network connection or retry with other browsers. If issue persists, please contact us so we can assist you."
  //         )
  //       );
  //     } else {
  //       message["success"](
  //         i18nTranslate(
  //           "Your videos has started to download! Please check your Downloads folder."
  //         )
  //       );
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  // ========== Single Link Progress Bar ==========

  const downloadAllVideos = async () => {
    window.open(downloadProgress.value?.link ?? "", "_blank");
  };

  /** Track progress of polled query. */
  const hasDownloadLink = ref(false);

  const {
    result: getOrderDownloadVideosProgressResult,
    loading: downloadProgressLoading,
  } = useQuery<GetOrderDownloadProgress, GetOrderDownloadProgressVariables>(
    getOrderDownloadProgressGql,
    () => ({
      input: { orderId: unref(orderIdRef) ?? "" },
    }),
    () => ({
      enabled: !!unref(orderIdRef),
      pollInterval: !hasDownloadLink.value
        ? DOWNLOAD_PROGRESS_POLL_INTERVAL
        : 0,
    })
  );

  const downloadProgress = computed(() => {
    const parsedResponse =
      parseGqlResponse<GetOrderDownloadProgress_getOrderDownloadProgress_Download>(
        "Download",
        getOrderDownloadVideosProgressResult.value,
        // Hide error if not allowed to download videos. Just return empty array.
        apiErrorCodes.DOWNLOAD_VIDEOS_NOT_ALLOWED_ERROR
      );

    return parsedResponse.data;
  });

  const isDownloadInProgress = computed(
    () =>
      downloadProgress.value?.status === "PROGRESS" &&
      (downloadProgress.value?.progress ?? 0) > 0
  );

  watch(downloadProgress, (progress) => {
    if (progress?.link) {
      hasDownloadLink.value = true;
    }
  });

  return {
    // List of Videos
    downloadableOrderVideos,
    downloadOrderVideosLoading,
    refetchDownloadOrderVideos,

    // Download Progress related
    downloadProgress,
    downloadProgressLoading,
    hasDownloadLink,
    isDownloadInProgress,
    // Handler
    downloadAllVideos,
  };
};
