import gql from "graphql-tag";

export const downloadOrderVideosGql = gql`
  query DownloadOrderVideos($input: DownloadOrderVideosInput!) {
    downloadOrderVideos(input: $input) {
      ... on DownloadVideosResults {
        results {
          __typename
          id
          embedUrl
        }
      }

      ... on ResponseErrors {
        errors {
          __typename
          code
          displayMessage
          message
        }
      }
    }
  }
`;

/** For polling */
export const getOrderDownloadProgressGql = gql`
  query GetOrderDownloadProgress($input: DownloadOrderVideosInput!) {
    getOrderDownloadProgress(input: $input) {
      ... on Download {
        progress
        link
        status
      }

      ... on ResponseErrors {
        errors {
          __typename
          code
          displayMessage
          message
        }
      }
    }
  }
`;
