import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "px-4" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "download-requests-page__grid mt-16" }
const _hoisted_4 = { class: "my-16" }
const _hoisted_5 = { class: "mt-8 mb-20" }
const _hoisted_6 = { class: "download-requests-page__feedback mt-16 py-24 px-6 md:px-24" }
const _hoisted_7 = { class: "my-14 inline-flex items-center gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_progress = _resolveComponent("a-progress")!
  const _component_a_typography = _resolveComponent("a-typography")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_ResultCard = _resolveComponent("ResultCard")!
  const _component_LoadingOutlined = _resolveComponent("LoadingOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    class: "download-requests-page text-center",
    "full-width": ""
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_a_skeleton, {
            key: 0,
            active: ""
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_a_typography_title, {
                level: 2,
                class: "mt-16"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.hasDownloadLink
              ? _ctx.t("You may now download your videos!")
              : _ctx.t("We're preparing your videos.")), 1)
                ]),
                _: 1
              }),
              (!_ctx.hasDownloadLink && _ctx.isDownloadInProgress)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_a_progress, {
                      percent: _ctx.downloadProgress?.progress ?? 0,
                      class: "w-96 max-w-full block mx-auto"
                    }, null, 8, ["percent"]),
                    _createVNode(_component_a_typography, { class: "text-sm mt-4" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Preparing download. This will be a one-time process...")), 1)
                      ]),
                      _: 1
                    })
                  ]))
                : (_ctx.hasDownloadLink)
                  ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
                      key: 1,
                      class: "mt-4"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t(
              "Kindly click the 'Download All' button below. Please allow pop-up in browser when prompted if download did not work."
            )), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.downloadableOrderVideos, (video) => {
                  return (_openBlock(), _createBlock(_component_ResultCard, {
                    key: video?.id,
                    "thumbnail-src": _ctx.getVimeoThumbnail(video?.embedUrl ?? ''),
                    "floating-content": "",
                    "hide-mask": "",
                    "hide-new-badge": ""
                  }, null, 8, ["thumbnail-src"]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_4, [
                (_ctx.downloadProgressLoading || !_ctx.hasDownloadLink)
                  ? (_openBlock(), _createBlock(_component_LoadingOutlined, {
                      key: 0,
                      class: "text-4xl"
                    }))
                  : (_ctx.hasDownloadLink)
                    ? (_openBlock(), _createBlock(_component_a_button, {
                        key: 1,
                        type: "primary",
                        onClick: _ctx.downloadAllVideos
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Download All")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_a_typography_paragraph, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t(
                "If you're taking time to download all the videos, you can download them one-by-one in here."
              )), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_button, {
                  type: "secondary",
                  onClick: _ctx.handleRedirectToRequests,
                  class: "my-2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("View Videos")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_a_typography_title, { level: 2 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t(`We'd Love Some Feedback`)), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_AppIcon, {
                  name: "twinkleEmoji",
                  class: "h-20"
                }),
                _createVNode(_component_AppIcon, {
                  name: "phoneEmoji",
                  class: "h-24"
                }),
                _createVNode(_component_AppIcon, {
                  name: "twinkleEmoji",
                  class: "h-20"
                })
              ]),
              _createVNode(_component_a_typography_paragraph, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("We value your thoughts about our service.")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openInNewTab(_ctx.feedbackLink))),
                class: "mt-4"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Send us your feedback")), 1)
                ]),
                _: 1
              })
            ])
          ], 64))
    ]),
    _: 1
  }))
}