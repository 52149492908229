
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import ResultCard from "@/shared/components/ResultCard.vue";
import { useDownloadOrderVideos } from "@/shared/composables/useDownloadOrderVideos";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import { useOrder } from "@/shared/composables/useOrder";
import { useVimeoEmbed } from "@/shared/composables/useVimeoEmbed";
import { openInNewTab } from "@/shared/utils/browser";
import { computed, defineComponent, onMounted, nextTick, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import message from "@/shared/utils/message";
import { LoadingOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const { redirectToOrderDetails, redirectToRequests } = useRedirectHelper();

    const { order, orderLoading, refetchOrder, isAllowedDownloadVideos } =
      useOrder({
        orderId: ref(String(route.params.orderId)),
      });
    const {
      // List of Videos
      downloadableOrderVideos,
      downloadOrderVideosLoading,
      refetchDownloadOrderVideos,
      // Download Progress related
      downloadProgress,
      downloadProgressLoading,
      hasDownloadLink,
      isDownloadInProgress,
      downloadAllVideos,
    } = useDownloadOrderVideos(computed(() => order.value?.id ?? ""));
    const { getVimeoThumbnail } = useVimeoEmbed();

    const handleRedirectToRequests = () => {
      redirectToRequests({ orderId: order.value?.id });
    };

    onMounted(async () => {
      await nextTick(async () => {
        await refetchOrder();
        await refetchDownloadOrderVideos();

        // If Order Status does not match
        if (!isAllowedDownloadVideos.value) {
          message["success"](
            t(
              "We got your comments. We'll update you once the videos have been revised."
            )
          );

          redirectToOrderDetails({ orderId: order.value?.id ?? "" });
        }
      });
    });

    return {
      t,
      getVimeoThumbnail,
      order,

      // List of Videos
      downloadableOrderVideos,
      downloadOrderVideosLoading,
      loading: computed(
        () => orderLoading.value || downloadOrderVideosLoading.value
      ),

      // Download progress bar
      downloadProgress,
      downloadProgressLoading,
      hasDownloadLink,
      isDownloadInProgress,

      // Handlers
      openInNewTab,
      feedbackLink: computed(() => order.value?.feedbackLink ?? ""),
      handleRedirectToRequests,
      downloadAllVideos,
    };
  },
  components: {
    PageLayout,
    ResultCard,
    AppIcon,
    LoadingOutlined,
  },
});
