
import { defineComponent, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { fallbackSrc } from "@/shared/utils/constants";
import { hasDatePassedNumberDays } from "@/shared/utils/date";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import DeleteOutlined from "@ant-design/icons-vue/DeleteOutlined";
import isArray from "lodash/isArray";

export default defineComponent({
  emits: ["on-click", "on-remove", "on-select"],
  props: {
    loading: Boolean,
    id: String,
    title: String,
    subtitle: String,
    thumbnailSrc: String,
    extraSubtitle: String,
    deleteButton: Boolean,
    selectable: Boolean,
    selectDisabled: Boolean,
    selected: Boolean,
    created: String,
    /**
     * Floats card body content (default slot) inside image.
     * Use only when there's content for `<slot #cover>`
     */
    floatingContent: Boolean,
    hideSubtitle: Boolean,
    hideMask: Boolean,
    hideNewBadge: Boolean,
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const isNew = computed(
      () => props.created && !hasDatePassedNumberDays(props.created, 14)
    );

    const toggleSelectedState = () => {
      if (props.selectable && !props.selectDisabled) {
        emit("on-select", props.id);
      }
    };

    /** Used to track hover state for showing tooltip */
    const isHovered = ref(false);
    const onMouseEnter = () => {
      isHovered.value = true;
    };
    const onMouseLeave = () => {
      isHovered.value = false;
    };

    return {
      t,
      fallbackSrc,
      isNew,
      isHovered,
      onMouseEnter,
      onMouseLeave,
      toggleSelectedState,
    };
  },
  methods: { isArray },
  components: { AppIcon, DeleteOutlined },
});
